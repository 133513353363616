import { FirstStepResponse } from 'components/Onboarding/OnboardingTypes/types';
import service from '../service';
import axios from 'axios';
import {
  AuthenticationPayload,
  CreateNewPasswordPayload,
  UserSessionModel,
} from 'types/authentication';

const BASE_URL = process.env.NEXT_PUBLIC_GATEWAY_URL;
const CORE_PATH = process.env.NEXT_PUBLIC_CORE_PATH;

/**
 * Login do usuário.
 * @param payload - Dados do usuário para autenticação.
 */
const userLogin = async (payload: AuthenticationPayload) => {
  const response = await axios.post<UserSessionModel | FirstStepResponse>(
    `${BASE_URL}/${CORE_PATH}/api/auth/seller/login`,
    payload,
  );

  return response?.data;
};

/**
 * Retorna os dados do refresh token do usuário caso o token
 * atual seja válido.
 */
const getRefreshToken = async () => {
  const response = await service.get<UserSessionModel>(
    `/${CORE_PATH}/api/auth/refresh`,
  );
  if (response.status !== 200) {
    throw new Error(`Erro ao solicitar o refresh token!`);
  }
  return response?.data;
};

/**
 * Refresh token no lado do server.
 * TODO: Implementar esta lógica na fução de refresh token.
 * @param token - Token de autenticação do usuário.
 */
const getRefreshTokenFromSSR = async (token: string) => {
  const response = await axios.get<UserSessionModel>(
    `${BASE_URL}/${CORE_PATH}/api/auth/refresh`,
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error(`Erro ao solicitar o refresh token!`);
  }
  return response?.data;
};

/**
 * Request para aceitar o termo de contrato.
 * @param termId - ID do contrato para ser aceito.
 */
const accecptTerm = async (termId: string) => {
  const response = await service.post(
    `/${CORE_PATH}/api/auth/terms-of-contract`,
    null,
    {
      params: {
        termId,
      },
    },
  );
  if (response.status !== 200) {
    throw new Error(response?.data?.message);
  }
  return response?.data;
};

/**
 * Logoff do usuário.
 */
const userLogoff = async () => {
  const response = await service.post(`/${CORE_PATH}/api/auth/web/logoff`, {});
  if (response.status !== 200) {
    throw new Error(response?.data?.message);
  }
  return response?.data;
};

/**
 * Solicita a recuperação de senha do lojista.
 * @param email - email do lojista.
 */
const passRecover = async (email: string) => {
  const response = await axios.post(
    `${BASE_URL}/${CORE_PATH}/api/auth/request-recover`,
    {
      email,
      type: 0,
    },
  );
  if (response?.status !== 200) {
    throw new Error('Erro ao solicitar recuperação de senha!');
  }
  return response?.data as { message: string; code: number };
};

/**
 * Gera nova senha após a solicitação de recuperação de senha do lojista.
 * @param payload - Dados para recuperação de senha (senha e confirmação da senha).
 */
const generatePassword = async (payload: CreateNewPasswordPayload) => {
  const response = await axios.post(
    `${BASE_URL}/${CORE_PATH}/api/auth/reset-recover`,
    payload,
  );
  if (response?.status !== 200) {
    throw new Error('Erro ao solicitar recuperação de senha!');
  }
  return response?.data as { code: number };
};

export {
  getRefreshToken,
  getRefreshTokenFromSSR,
  accecptTerm,
  userLogin,
  userLogoff,
  passRecover,
  generatePassword,
};
